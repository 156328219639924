export const PRE_RENDER_STATE_NOT_INITIALIZED = 'NOT_INITIALIZED';
export const PRE_RENDER_STATE_PENDING = 'PENDING';
export const PRE_RENDER_STATE_ENABLED_IN_BACKGROUND = 'ENABLED_IN_BACKGROUND';
export const PRE_RENDER_STATE_VISIBLE = 'VISIBLE';
export const PRE_RENDER_STATE_ERROR = 'ERROR';
export const PRE_RENDER_STATE_INITIAL = PRE_RENDER_STATE_PENDING;

export type PrerenderedCheckoutRootState =
    | typeof PRE_RENDER_STATE_NOT_INITIALIZED
    | typeof PRE_RENDER_STATE_PENDING
    | typeof PRE_RENDER_STATE_ENABLED_IN_BACKGROUND
    | typeof PRE_RENDER_STATE_VISIBLE
    | typeof PRE_RENDER_STATE_ERROR;

/**
 * Checkout params originally came from the url, so there's a lot of code that expect boolean
 * values to be strings. This type is used to represent those values.
 */
type TrueFalse = 'true' | 'false';

export type PrerenderedCheckoutParams = {
    quantity?: string;
    skuId?: string;
    message?: string;
    offerAmount?: string;
    offerCurrency?: string;
    sentMakeOfferModalMessage?: TrueFalse;
    triggerType?: string;
    triggerPage?: string;
    priceBookName?: string;
    abtests?: string;
    itemId?: string;
    acceptOffer?: TrueFalse;
};

export type PrerenderedCheckoutDeferredAction = () => void;

export type PrerenderedCheckoutState = {
    rootState: PrerenderedCheckoutRootState;
    params: PrerenderedCheckoutParams;
    deferredActions: PrerenderedCheckoutDeferredAction[];
};

let prerenderedCheckoutState: PrerenderedCheckoutState = {
    rootState: PRE_RENDER_STATE_INITIAL,
    params: {},
    deferredActions: [],
};
const stateUpdateListeners: Set<(state: PrerenderedCheckoutState) => void> = new Set();

export const addStoreUpdateListener = (
    listener: (state: PrerenderedCheckoutState) => void
): (() => void) => {
    stateUpdateListeners.add(listener);

    return () => {
        stateUpdateListeners.delete(listener);
    };
};

export const getPrerenderedCheckoutState = (): PrerenderedCheckoutState => prerenderedCheckoutState;

const updatePrerenderedCheckoutState = (state: PrerenderedCheckoutState): void => {
    prerenderedCheckoutState = state;
    stateUpdateListeners.forEach(listener => listener(prerenderedCheckoutState));
};

export const setPrerenderedCheckoutRootState = (rootState: PrerenderedCheckoutRootState): void => {
    updatePrerenderedCheckoutState({
        ...prerenderedCheckoutState,
        rootState,
    });
};

export const updatePrerenderedCheckoutParams = (params: PrerenderedCheckoutParams): void => {
    updatePrerenderedCheckoutState({
        ...prerenderedCheckoutState,
        params,

        // possible to have different actions with new params
        // TODO: Will this clear something that it shouldn't?
        deferredActions: [],
    });
};

export const setPrerenderedCheckoutDeferredActions = (
    action: PrerenderedCheckoutDeferredAction
): void => {
    updatePrerenderedCheckoutState({
        ...prerenderedCheckoutState,
        deferredActions: [...prerenderedCheckoutState.deferredActions, action],
    });
};

export const resetPreRenderedCheckoutState = (): void => {
    updatePrerenderedCheckoutState({
        rootState: PRE_RENDER_STATE_INITIAL,
        params: {},
        deferredActions: [],
    });
};
