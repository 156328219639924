import { createContext, FC, ReactNode, useContext } from 'react';

export type ServerVarsContextType = {
    isBot?: boolean; // should be set only for SSR
    isDesktop?: boolean | null;
    isMobile?: boolean | null;
    isSbAdsEnabled?: boolean | null;
    isTablet?: boolean | null;
    clientConf?: { [key: string]: string | number | undefined } | null;
    localeOrigin?: string | null;
    isSearchQueryApplied?: boolean | null;
    locale?: string | null;
    originalUrl?: string | null;
    isSoldPDPV1Variant?: boolean | null;
    isItemTileShorterHeight?: boolean | null;
    isNewNetPrice15Filter?: boolean | null;
    isRemovePricingGuidancePDPVariant?: boolean;
};

export const ServerVarsContext = createContext<ServerVarsContextType>({
    isBot: false,
    isDesktop: null,
    isMobile: null,
    isSbAdsEnabled: null,
    isTablet: null,
    clientConf: {},
    localeOrigin: null,
    locale: null,
    originalUrl: null,
    isSearchQueryApplied: null,
    isItemTileShorterHeight: null,
    isNewNetPrice15Filter: null,
    isRemovePricingGuidancePDPVariant: false,
});

export const useServerVarsContext = (): ServerVarsContextType => useContext(ServerVarsContext);

export const ServerVarsContextConsumer = ServerVarsContext.Consumer;

export const ServerVarsContextProvider: FC<ServerVarsContextType & { children: ReactNode }> = ({
    children,
    isDesktop,
    isMobile,
    isSbAdsEnabled,
    isTablet,
    clientConf,
    localeOrigin,
    originalUrl,
    isSearchQueryApplied,
    locale,
    isSoldPDPV1Variant,
    isItemTileShorterHeight,
    isNewNetPrice15Filter,
    isBot,
    isRemovePricingGuidancePDPVariant,
}) => {
    return (
        <ServerVarsContext.Provider
            value={{
                isDesktop,
                isMobile,
                isSbAdsEnabled,
                isTablet,
                clientConf,
                localeOrigin,
                originalUrl,
                isSearchQueryApplied,
                locale,
                isSoldPDPV1Variant,
                isNewNetPrice15Filter,
                isItemTileShorterHeight,
                isBot,
                isRemovePricingGuidancePDPVariant,
            }}
        >
            {children}
        </ServerVarsContext.Provider>
    );
};
