import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';
import { AbTestV2Func } from 'dibs-buyer-layout/exports/serverABTestV2';

export const BEST_SELLERS_COLLECTION = 'bestSellersCollection' as const;
export const PERSONALIZED_RERANK_TEST_NAME = 'Personalized-Rerank' as const;
export const SPONSORED_LISTINGS_TEST_NAME = 'sponsored-listings' as const;
export const ITEM_TILE_SHORTER_HEIGHT = 'ItemTileShorterHeight' as const;
export const NET_PRICE_15_FILTER_TEST_NAME = 'NetPrice15Filter' as const;
export const USER_ENGAGEMENT_BOOST_TEST_NAME = 'UserEngagementBoost' as const;
export const HIDE_SOLD_ITEMS = 'HideSoldItems' as const;

export const getPersonalizedRerankAbTestVariant = (): string | undefined =>
    getAbTestV2(PERSONALIZED_RERANK_TEST_NAME)?.variant;

export const isItemTileShorterHeightVariantServer = (getAbTestV2Server: AbTestV2Func): boolean =>
    getAbTestV2Server(ITEM_TILE_SHORTER_HEIGHT)?.variant === 'variant';

const getUserEngagementBoostAbTest = (getAbTestFunc: AbTestV2Func): string | undefined =>
    getAbTestFunc(USER_ENGAGEMENT_BOOST_TEST_NAME)?.variant;

export const isUserEngagementBoostAbTestVariantOrControl = (getAbTestFunc = getAbTestV2): boolean =>
    !!getUserEngagementBoostAbTest(getAbTestFunc);

export const isUserEngagementBoostAbTestControl = (getAbTestFunc = getAbTestV2): boolean =>
    getUserEngagementBoostAbTest(getAbTestFunc) === 'control';

export const isHideSoldItemsVariant = (): boolean =>
    getAbTestV2(HIDE_SOLD_ITEMS)?.variant === 'variant';
