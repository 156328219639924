export const FILTER_REFINE_SEARCH = 'refineSearch';
export const FILTER_SEARCH = 'search';

export const MEASUREMENTS = 'measurements';
export const SOLD = 'sold';
export const SHOW_SOLD = 'showSold';
export const LATEST = 'latest';
export const NET_PRICE = 'net-price';
export const NET_PRICE_TRADE_DISCOUNT = 'netTradePriceDiscount';
export const PRICE = 'price';
export const CUSTOMIZABLE = 'customizable';
export const RETURNABLE = 'returnable';
export const CURRENCY = 'currency';
export const SORT = 'sort';
export const RECOGNIZED = 'recognized';
export const SELLER_PK = 'sellerPk';
export const TOP_SELLER = 'topSeller';
export const STRATEGIC_PARTNER = 'isSellerStrategicPartner';
export const SALE = 'sale';
export const ON_SALE = 'onSale';
export const FRAME_INCLUDED = 'frameIncluded';
export const FRAMING_STATUS = 'framing';
export const FRAMING_OPTION_AVAILABLE = 'framingOptionAvailable';
export const MEASUREMENT_UNIT = 'measurement-unit';
export const CATEGORY = 'category';
export const RING_SIZE = 'ring-size';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const AUCTION = 'auction';
export const RESERVE_PRICE = 'reserve-price';
export const SUBJECT_MATTER = 'subject-matter';

export const MEASUREMENT_STORAGE_KEY = 'display-measurements';
export const SOLD_STORAGE_KEY = 'showSold';

export const DISPLAY_PREFERENCE_OPTIONS = [
    MEASUREMENTS,
    SOLD,
    LATEST,
    NET_PRICE,
    CUSTOMIZABLE,
    RETURNABLE,
    RECOGNIZED,
    TOP_SELLER,
    STRATEGIC_PARTNER,
];

export const LEAD_TIME_BUCKET_DEFAULT_URLLABEL = '0_25-weeks';

export const VALID_DIMENSION_FILTERS = ['height', 'width', 'length', 'depth'];

export const DEFAULT_LOCATION_LABELS = ['usa-north-america', 'europe'];

export const CLIENT_SIDE_FILTERS = [MEASUREMENTS];
